
Sugar = require('sugar/string');
require('sugar-inflections');

window.reset_condition_sbody = () ->
  $('#dialogue_condition_sbody_property').empty()
  $('#dialogue_condition_sbody_property').append('<option>Select Property</option>')
  # $('#dialogue_condition_sbody_operator').
  $('#dialogue_condition_sbody_operator option:eq(0)').prop('selected', true)
  $('#dialogue_condition_sbody_value').val('')


$(document).on('change', '#dialogue_condition_sbody_object', (ev) ->
  window.reset_condition_sbody()
  return if ev.target.value == ''
  [klass, smid] = ev.target.value.split(':')
  klass_tableize = Sugar.String(klass).underscore().pluralize()
  $.ajax({
    url: "/#{klass_tableize}/#{smid}.json",
    global: false,
    success: (data) ->
      # new_options = Object.keys(data.properties).map((k) -> [k, k])
      props = Object.keys(data.properties)
      for prop in props
        new_opt = $('<option>').val(prop).text(prop)
        $('#dialogue_condition_sbody_property').append(new_opt)
    })
)