
if ((window.location.hostname != 'dev.storymapr.com') && (window.location.hostname != 'staging.storymapr.com')) {

  console.log("Not dev")

  document.addEventListener('turbo:load', () => {

    amplitude.getInstance().init("7ef4b6a638c85643881b9bbb400a6fee");

    $(document).on('click', '#new_character', (ev) => {
      amplitude.getInstance().logEvent('CHARACTER_CREATE_INTENT');
    })

    $(document).on('click', '#create_character', (ev) => {
      amplitude.getInstance().logEvent('CHARACTER_CREATE_COMPLETE');
    })

    // dtree workbench buttons

    $(document).on('click', '#add_node_button', (ev) => {
      amplitude.getInstance().logEvent('DNODE_ADD_INTENT');
    })

    $(document).on('click', '#add_reply_button', (ev) => {
      amplitude.getInstance().logEvent('DNODE_REPLY_INTENT');
    })

    $(document).on('click', '#suggest_reply_button', (ev) => {
      amplitude.getInstance().logEvent('DNODE_SUGGEST_INTENT');
    })

    $(document).on('click', '#add_jump_button', (ev) => {
      amplitude.getInstance().logEvent('JUMP_ADD_INTENT');
    })

    // scene manager buttons
    $(document).on('click', '#add_scene_button', (ev) => {
      amplitude.getInstance().logEvent('STORY_ADD_INTENT');
    })

    $(document).on('click', '#add_next_button', (ev) => {
      amplitude.getInstance().logEvent('STORY_REPLY_INTENT');
    })

    $(document).on('click', '#suggest_scene_button', (ev) => {
      amplitude.getInstance().logEvent('STORY_SUGGEST_INTENT');
    })

    // story public player
    $(document).on('click', '.restart_story_analytics', (ev) => {
      tracked_story_id = $(ev.currentTarget).data('story-id')
      amplitude.getInstance().logEvent('STORY_REPLAY', {story_id: tracked_story_id });
    })


    $(document).on('click', '.navbar-nav .nav-item .nav-link', (ev) => {
      const href = ev.target.href
      amplitude.getInstance().logEvent('NAVBAR CLICK', {href: href});
    })

    $(document).on('click', '.fa-play, .play_node', (ev) => {
      try {
        objType = window.location.pathname.split('/')[1]
        amplitude.getInstance().logEvent('PLAY CLICK', {objType: objType});
        return true
      } catch (err) {
        console.log("Couldn't track play")
        return false
      }
    })

    
  })

  // end very large if with hosts
}
