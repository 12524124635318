import fileUpload from './fileUpload.js'
import iro from '@jaames/iro'
// code starts here

window.alt_props_bindings('character', '.character_add_field')

$(document).on('turbo:load', () => {

  $(document).on('input', '#character_name', (ev) => {
    let name = ev.target.value
    if (name == '') {name = 'Speaker'};
    $('#speaker_name').text(name)
  })


  if ($('#characterImage').length > 0) {
    fileUpload('#characterImage')
  }

  if ($('#character_color_picker').length > 0) {

    const setColor = $('#character_color').val()
    const boxPicker = new iro.ColorPicker("#character_color_picker", {
      width: 200,
      color: setColor,
      borderWidth: 1,
      borderColor: "#fff",
      layout: [
        {
          component: iro.ui.Box,
        },
        {
          component: iro.ui.Slider,
          options: {
            sliderType: 'hue'
          }
        }
      ]
    });

    

    boxPicker.on('color:change', (color) => {
      $('#character_color').val(color.hexString)
      $('.ui_node .title_wrapper').css('background-color', color.hexString)
      $('.ui_node .content').css('color', color.hexString)
    })
  }
  
  
})

