# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

$(document).on('click', '#send_user_invite', (ev) ->
  ev.preventDefault()
  email = $('#invite_email').val()
  organization_id = ev.target.dataset.organizationId
  $.ajax({
    type: 'POST'
    url: "/organizations/#{organization_id}/invite"
    headers: {'Accept': 'application/json'}
    data: {email: email }
    success: (data) ->
      $('#invite_status').text("#{data.message}")
      $('#invite_email').val('')
      return true
    error: (status, data) ->
      $('#invite_status').text("#{status.responseJSON.message}")
      return false



  })
)

$(document).on('click', '#remove_user_from_org', (ev) ->
  ev.preventDefault()
  org_id = ev.target.dataset.organizationId
  user_id = ev.target.dataset.userId
  $.ajax({
  type: 'DELETE'
  url: "/organizations/#{org_id}/users/#{user_id}"
  headers: {'Accept': 'application/json'}
  success: (data) ->
    $("#user_#{user_id}").remove()
    $('#user_notice').text("#{data.message}")
    return true
  error: (status, data) ->
    $('#user_notice').text("#{data.message}")
    return false

  })

)

