
import {
  Core,
  DragDrop,
  Informer,
  ProgressBar,
  ThumbnailGenerator,
  AwsS3,
} from 'uppy'

function fileUpload(fileInput, thumbSize=300) {

  const me = document.querySelector(`${fileInput}`)
  const hiddenInput = document.querySelector(`${fileInput}-hidden`),
        imagePreview = document.querySelector(`${fileInput}-preview`),
        inputWrapper = me.parentNode,
        submit_button = $(me).closest('form').find(':submit')

  // remove our file input in favour of Uppy's
  inputWrapper.removeChild(me)

  const uppy = Core({
      autoProceed: true,
      allowMultipleUploads: false,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif']
      }
      
    })
    .use(DragDrop, {
      target: inputWrapper,
      // height: '150px',
      // width:  '200px',
    })
    .use(Informer, {
      target: inputWrapper,
    })
    .use(ProgressBar, {
      target: imagePreview.parentNode,
    })
    .use(ThumbnailGenerator, {
      thumbnailWidth: thumbSize,
    })
    .use(AwsS3, {
      companionUrl: '/', // will call the presign endpoint on `/s3/params`
    })

  uppy.on('thumbnail:generated', (file, preview) => {
    // show preview of the image using URL from thumbnail generator
    imagePreview.src = preview
  })

  uppy.on('upload', (data) => {
    submit_button.attr('disabled', true)
  })

  uppy.on('upload-success', (file, response) => {
    // construct uploaded file data in the format that Shrine expects
    const uploadedFileData = {
      id: file.meta['key'].match(/^cache\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      }
    }

    // set hidden field value to the uploaded file data so that it's submitted
    // with the form as the attachment
    hiddenInput.value = JSON.stringify(uploadedFileData)
    submit_button.attr('disabled', false)
    uppy.reset()
  })
}

export default fileUpload