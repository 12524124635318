
Sugar = require('sugar/string');
require('sugar-inflections');

$(document).on('change', '#dialogue_atype', (ev) -> 
  $('.actionBody').addClass('hidden')
  switch ev.target.value
    when 'custom'
      revealId = 'cbody';
    when 'storymapr'
      revealId = 'sbody';
    else
      alert("Please choose an option")
  $("##{revealId}_wrapper").removeClass('hidden') 

)

window.reset_sbody = () ->
  $('#dialogue_action_sbody_property').empty()
  $('#dialogue_action_sbody_property').append('<option>Select Property</option>')
  # $('#dialogue_action_sbody_operator').
  $('#dialogue_action_sbody_operator option:eq(0)').prop('selected', true)
  $('#dialogue_action_sbody_value').val('')


$(document).on('change', '#dialogue_action_sbody_object', (ev) ->
  window.reset_sbody()
  return if ev.target.value == ''
  [klass, smid] = ev.target.value.split(':')
  klass_tableize = Sugar.String(klass).underscore().pluralize()
  $.ajax({
    url: "/#{klass_tableize}/#{smid}.json",
    global: false,
    success: (data) ->
      # new_options = Object.keys(data.properties).map((k) -> [k, k])
      props = Object.keys(data.properties)
      for prop in props
        new_opt = $('<option>').val(prop).text(prop)
        $('#dialogue_action_sbody_property').append(new_opt)
    })
)