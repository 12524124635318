import fileUpload from './fileUpload.js'
import iro from '@jaames/iro'


// code starts here
window.alt_props_bindings('location', '.location_add_field')

$(document).on('turbo:load', () => {

  if ($('#locationImage').length > 0) {
    fileUpload('#locationImage', 500)
  }

  $(document).on('input', '#location_name', (ev) => {
    let name = ev.target.value
    if (name == '') {name = 'None'};
    $('.location').text(name)
  })

  if ($('#locationImage').length > 0) {
    fileUpload('#locationImage')
  }

  if ($('#location_color_picker').length > 0) {

    const setColor = $('#location_color').val()


    const boxPicker = new iro.ColorPicker("#location_color_picker", {
      width: 200,
      color: setColor,
      borderWidth: 1,
      borderColor: "#fff",
      layout: [
        {
          component: iro.ui.Box,
        },
        {
          component: iro.ui.Slider,
          options: {
            sliderType: 'hue'
          }
        }
      ]
    });

    

    boxPicker.on('color:change', (color) => {
      $('#location_color').val(color.hexString)
      $('.ui_node .title_wrapper').css('background-color', color.hexString)
      $('.ui_node .content').css('color', color.hexString)
    })
  }

})
