const Rails = require("@rails/ujs");
import { Turbo } from "@hotwired/turbo-rails"

let Sugar = require('sugar/string');
require('sugar-inflections');

// Start load events....let's hopefully get away from this

$(document).on('turbo:load', () => {



  $(document).on('click', "#opt_out_emails", (ev) => {    
    // const my_form = $(ev.target).parent('form')[0]
    const my_form = $('#no_email_form')[0]
    Rails.fire(my_form, 'submit')
  })

  $(document).on('ajax:success', '#no_email_form', (ev) => {
    $('#email_conf').fadeIn(0)
    $('#email_conf').text('Saved!')
    $('#email_conf').fadeOut(3000)
  })

  $('[data-toggle="tooltip"]').tooltip()

}) 

// end load events


$(document).on('change', '.owner_filter', (ev) => {
  let model = ev.target.dataset['model'];
  let filter_val = ev.target.value;
  Turbo.visit("/" + Sugar.String(model).underscore().pluralize() + "?owner=" + filter_val);
})

