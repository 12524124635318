$(document).on('shown.bs.modal', '#loading_modal', (e) ->
  if window.is_ajaxing == false
    $('#loading_modal').modal('hide')
)


$(document).ajaxStart(() ->
  $('#loading_modal').modal('show')
  window.is_ajaxing = true
)

$(document).ajaxStop(() ->
  $('#loading_modal').modal('hide')
  window.is_ajaxing = false
)

$(document).on('click', '.reload_link', (ev) ->
  ev.stopPropagation()
  ev.preventDefault()
  Turbo.visit(window.location)


)

$(document).on('click', '.btn-to', (ev) ->
  ev.stopPropagation()
  ev.preventDefault()
  Turbo.visit($(ev.target).data('href'))
)

$(document).on('click', '.popup', (ev) ->
  ev.stopPropagation()
  ev.preventDefault()
  my_name = '_blank'
  my_name = $(this).data('popup-name') if $(this).data('popup-name')?

  my_opts = 'width=800,height=600'
  my_opts = $(this).data('popup-opts') if $(this).data('popup-opts')?

  window.open(ev.target.href, my_name, my_opts)

)

$(document).on('click', '.clippy', (ev) ->
  ev.stopPropagation()
  ev.preventDefault()
  my_text = $(this).data('clippyText')
  clippy = $('#clipboard')
  clippy.val(my_text)
  clippy.attr('type', 'text')
  clippy.select()
  document.execCommand("copy")
  clippy.attr('type', 'hidden')
  $('#notice').html("You've copied to the clipboard: <strong>#{my_text}</strong>")

)

# Intentionally global functions.
window.gsu_decrement = (decrement_amount) ->
  $('.remaining_gsu_counter').each(() ->
    current_ai_amount = $(this).data('gsu-remaining')
    new_amount = current_ai_amount - decrement_amount
    if new_amount <= 0
      new_amount = 0
      $('.ai_button').attr('disabled', true)
      window.gon.ai_enabled = false
    $(this).data('gsu-remaining', new_amount)
    $(this).text(new_amount)
  )


window.gen_bson_uuid = () ->
  timestamp = (new Date().getTime() / 1000 | 0).toString(16);
  return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, (() -> (Math.random() * 16 | 0).toString(16).toLowerCase()))
  

window.alt_props_bindings = (model, dom_selector) ->

  prop_row_html = """
    <div class="form-row mb-2">
      <div class="col-6">
        <div class="input-group">
          <input type="text" class="#{model}_alt_props_key alt_props_key form-control" placeholder="Attribute Name"/> 
          <span class='property_separator ml-2 pt-2'> : </span>
        </div>

      </div>
      <div class="col-6">
        <div class="input-group">
          <input type="text" class="#{model}_alt_props_val alt_props_val form-control" placeholder="eg. true, 1, pizza"/>
          <button type="button" class="btn btn-outline-danger remove_alt_prop ml-2">-</button>
        </div>
      </div>
    </div>
  """
  $(document).on('click', dom_selector, (ev) ->
    ev.stopPropagation()
    ev.preventDefault()
    $('#extra_field_wrapper').append($(prop_row_html))

  )



  $(document).on('input', ".#{model}_alt_props_key", () ->
    my_val_box = $(this).closest('.form-row').find(".#{model}_alt_props_val")
    # my_val_box = $(this).siblings('input')[0]
    key_name = $(this).val()

    $(my_val_box).attr(name: "#{model}[alt_props][#{key_name}]")

  )

  $(document).on('click', '.remove_alt_prop', () ->
    $(this).closest('.form-row').remove()
  )

  

