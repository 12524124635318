// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
// import "@hotwired/turbo-rails"
export const getTurbo = async () => {
  if (!window.Turbo) {
    const Turbo = require('@hotwired/turbo-rails')
    // await Turbo.start()
    window.Turbo = Turbo
  }
  return window.Turbo
}

getTurbo()
// import '@hotwired/turbo'



require("channels")

let Sugar = require('sugar/string');
require('sugar-inflections');

import '../js/bootstrap_custom.js'
import '../stylesheets/application.scss'
import '../js/_common.coffee'
import 'amplitude-js'
import '../js/user_tracking.js' 

// This is going to be the new method of everything
import '../js/ui_bindings.js'

import '../js/characters.js'
import '../js/locations.js'

import '../js/organizations.coffee'

import '../js/dialogue_actions.coffee'
import '../js/dialogue_conditions.coffee'







// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// document.addEventListener('turbolinks:load', () => {
//   $('[data-toggle="tooltip"]').tooltip()
// })




// $(document).on('change', '.owner_filter', (ev) => {
//   let model = ev.target.dataset['model'];
//   let filter_val = ev.target.value;
//   Turbo.visit("/" + Sugar.String(model).underscore().pluralize() + "?owner=" + filter_val);
// })

  // if ('scrollRestoration' in history) {
  //   history.scrollRestoration = 'manual'
  // }

  // var SCROLL_POSITION = 'scroll-position'
  // var PAGE_INVALIDATED = 'page-invalidated'

  // // Persist the scroll position on refresh
  // addEventListener('beforeunload', function() {
  //   sessionStorage.setItem(SCROLL_POSITION, JSON.stringify(scrollData()))
  // });

  // // Invalidate the page when the next page is different from the current page
  // // Persist scroll information across pages
  // document.addEventListener('turbolinks:before-visit', function (event) {
  //   if (event.data.url !== location.href) {
  //     sessionStorage.setItem(PAGE_INVALIDATED, 'true')
  //   }
  //   sessionStorage.setItem(SCROLL_POSITION, JSON.stringify(scrollData()))
  // })

  // // When a page is fully loaded:
  // // 1. Get the persisted scroll position
  // // 2. If the locations match and the load did not originate from a page
  // // invalidation,
  // // 3. scroll to the persisted position if there, or to the top otherwise
  // // 4. Remove the persisted information
  // addEventListener('turbolinks:load', function (event) {
  //   var scrollPosition = JSON.parse(sessionStorage.getItem(SCROLL_POSITION))

  //   if (shouldScroll(scrollPosition)) {
  //     scrollTo(scrollPosition.scrollX, scrollPosition.scrollY)
  //   } else {
  //     scrollTo(0, 0)
  //   }
  //   sessionStorage.removeItem(PAGE_INVALIDATED)
  // });

  // function shouldScroll(scrollPosition) {
  //   return (scrollPosition
  //     && scrollPosition.location === location.href
  //     && !JSON.parse(sessionStorage.getItem(PAGE_INVALIDATED)))
  // }

  // function scrollData() {
  //   return {
  //     scrollX: scrollX,
  //     scrollY: scrollY,
  //     location: location.href
  //   }
  // }